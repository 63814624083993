import { cn } from "@/helpers/className";

export type Icons =
  | "angle-down"
  | "angle-left"
  | "angle-right"
  | "arrow-right"
  | "arrow-right-light"
  | "arrow-vrt"
  | "article"
  | "bell"
  | "briefcase"
  | "calendar"
  | "chat"
  | "check"
  | "clock"
  | "close"
  | "coffee"
  | "coin"
  | "confetti"
  | "contract"
  | "contact"
  | "couch"
  | "delete"
  | "download"
  | "dot"
  | "eye"
  | "faq"
  | "facebook"
  | "fingerprint"
  | "fire"
  | "flag"
  | "gear"
  | "hamburger"
  | "hammer"
  | "handshake"
  | "hashtag"
  | "heart"
  | "history"
  | "info"
  | "instagram"
  | "lifebuoy"
  | "list"
  | "linkedin"
  | "loader"
  | "location"
  | "love"
  | "love-fill"
  | "magnify"
  | "mail"
  | "map"
  | "map-pin"
  | "paperplane"
  | "pause"
  | "phone"
  | "play"
  | "play-circle"
  | "play-fill"
  | "play-thin"
  | "plus"
  | "profile"
  | "question"
  | "search"
  | "share"
  | "share-mail"
  | "share-link"
  | "share-linkedin"
  | "share-whatsapp"
  | "share-x"
  | "share-facebook"
  | "sign-out"
  | "sliders"
  | "sporza"
  | "tag"
  | "twitter"
  | "users"
  | "uprofile"
  | "vrt_nws"
  | "vrt_max"
  | "vacation"
  | "warning"
  | "wave"
  | "youtube"

interface IconProps {
  className?: string;
  name: Icons;
  ariaLabel?: string;
}

const Icon: React.FC<IconProps> = ({ name, className = "", ariaLabel, ...props }) => {
  const classNames = cn("fill-current", className);
  return (
    <svg
      className={classNames}
      aria-hidden={ariaLabel ? undefined : "true"}
      aria-labelledby={ariaLabel ? "custom-svg" : undefined}
      role={ariaLabel ? "img" : undefined}
      {...props}>
      {ariaLabel && <title id={"custom-svg"}>{ariaLabel}</title>}
      <use href={`/nl/img/icons.svg#${name}`} />
    </svg>
  );
};

export default Icon;
